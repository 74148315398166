import React, { lazy, Suspense } from "react";
import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import EngagementManagement from "./components/engagementManagement/EngagementManagement";
import Layout from "./components/layout/Layout";
import "./custom.css";
import AuthenticationService from "./services/AuthenticationService";
import ICurrentUser from "./interfaces/ICurrentUser";
import IAppProps from "./interfaces/IAppProps";
import { setCurrentUser } from "./store/actions";
import { isEmpty } from './helpers/StringHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const DocumentManagement = lazy(() => import('./components/documentManagement/DocumentManagement'));
const DocumentRecycleBin = lazy(() => import('./components/DocumentRecycleBin/DocumentRecycleBin'));
const TaskListEditor = lazy(() => import('./components/TaskListEditor/TaskListEditor'));
const Team = lazy(() => import('./components/team/Team'));
const Reports = lazy(() => import('./components/Reports/Reports'));
const HelpCenter = lazy(() => import('./components/HelpCenter/HelpCenter'));
const AccountSettings = lazy(() => import('./components/AccountSettings/AccountSettings'));
const ErrorPage = lazy(() => import('./components/error/Error'));
const NotFoundPage = lazy(() => import('./components/notFound/NotFound'));

/**
 * A history object to use for navigation.
 * When history is passed to </Router>, the history object is available for use within components
 * This allows developers to navigate users within the React application programmatically, among other things
 */
const history = createBrowserHistory();

const App = (props: IAppProps): JSX.Element => {

    React.useEffect(() => {

        const authenticateUser = async (): Promise<void> => {
            const authenticationService = new AuthenticationService();
            const signedInUser = await authenticationService.loginViaRedirect();
            props.setCurrentUser({ currentUser: signedInUser });
        };


        if (isEmpty(props.currentUser.accessToken)) {
            authenticateUser();
        }

    }, []);

    return (
        <>
            {props.currentUser.accessToken &&
                <Router history={history}>
                    <Layout>
                        <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
                            <Switch>
                                <Route exact path="/" component={Dashboard} />
                                <Route path="/document-management" component={DocumentManagement} />
                                {
                                    props.currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/document-recycle-bin" component={DocumentRecycleBin} />)
                                }
                                <Route exact path="/engagement-management" component={EngagementManagement} />
                                <Route exact path="/engagement-management/:id" component={EngagementManagement} />
                                {
                                    props.currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/engagement-management/request-list/:id" component={TaskListEditor} />)
                                }
                                {
                                    props.currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/internal-reporting" component={Reports} />)
                                }
                                <Route exact path="/team" component={Team} />
                                <Route exact path="/help-center" component={HelpCenter} />
                                <Route exact path="/account-settings" component={AccountSettings} />
                                <Route exact path="/error" component={ErrorPage} />
                                <Route path="*" component={NotFoundPage} />
                            </Switch>
                            <style>
                                {`div.uv-icon {
                                    display: block;
                                }`}
                            </style>
                        </Suspense>
                    </Layout>
                </Router>
            }
        </>
    );
};


const mapStateToProps = (state: any) => {
    return ({
        currentUser: state.currentUser as ICurrentUser
    })
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCurrentUser: (data: any) => dispatch(setCurrentUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
