import { Link } from "react-router-dom"
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Add, GetApp, MoreHoriz } from '@material-ui/icons';


import HelpfulHintIcon from "./HelpfulHintIcon";

const restrictedFileImportantMessage = <><strong>IMPORTANT:</strong> If you are uploading <strong>sensitive files</strong> that only some users should be able to access, please select <strong>Upload Restricted File</strong> using the  </>
const unrestrictedFileWarningMessage = <><strong>Note: <u>Any</u></strong> user with access to this engagement portal can <strong>view any unrestricted</strong> files that have been uploaded.</>

export const publicEngagementManagementHints = [
    <>{restrictedFileImportantMessage} <HelpfulHintIcon><Add /></HelpfulHintIcon> icon.</>,
    unrestrictedFileWarningMessage,
    "Click on a request line to view the details pane on the right.",
    "To easily see requests assigned to you, use the Assignee filter.",
    "You can change the Assignee in the drop down and click Save.",
    <>To upload <strong>unrestricted</strong> files, you can click and drag them directly onto Attachments.</>,
    "Uploading a file or commenting will change the request status to \"Submitted by Client.\"",
]

export const internalEngagementManagementHints = [
    <>
        To import a request list, click on the <HelpfulHintIcon><PublishIcon /></HelpfulHintIcon> icon.
        Note: importing another file will append those requests to the bottom of the existing list.
    </>,
    <>
        To edit multiple requests at a single time, click on the <HelpfulHintIcon><EditIcon /></HelpfulHintIcon> icon, select rows (shift+click or CTRL+click), modify values on the right, and click <HelpfulHintIcon><SaveIcon /></HelpfulHintIcon>.
    </>,
    <>
        To download files associated with requests, click on the <HelpfulHintIcon><GetApp /></HelpfulHintIcon> icon, select requests, and click OK.
    </>,
    <>
        If records appear to be missing, you may need to clear filters <HelpfulHintIcon><ViewColumnIcon /></HelpfulHintIcon>.
    </>,
    <>
        To upload <strong>unrestricted</strong> files, you can click and drag them directly onto Attachments.
    </>,
    <>
        To upload <strong>restricted</strong> files with sensitive information, click the + icon in the Attachments section and select the Restricted option.
    </>,
]

export const dashboardHints = [
    "Use the client selector in the upper right to view your engagement portal(s).",
    'Use the filter and the star icon to favorite/pin clients to the top of the client selector.',
    "The Dashboard widgets account for items where you are the Responsible party.",
    "If your work is organized around requests, access them on the Engagement Requests screen.",
    "If you prefer simplified file sharing, you can utilize the Documents screen to drag and drop files.",
    <>Notification settings and <Link to='/help-center' target="_blank" rel="noopener noreferrer">Help resources</Link> are available via the avatar in the upper right corner.</>,
]

export const documentManagementHints = [
    <>{restrictedFileImportantMessage} <HelpfulHintIcon><MoreHoriz /></HelpfulHintIcon> icon.</>,
    unrestrictedFileWarningMessage,
    "Drag and drop onto folders (e.g. Client Documents) is supported.",
    "Final deliverables are uploaded by Moss Adams into the Final Documents folder.",
    "Restricted files are marked with a padlock icon.",
    "If you upload a file, you may modify that file's access via the File Details pane.",
    "By toggling Request Mode on and clicking on the engagement folder (e.g. 2022 Audit - Other), the requests will be displayed. You can click and drag a file's 12 dot handle onto a Request Title to associate a file to a request.",
    <>Prospect/Client-level folders can be created via the <HelpfulHintIcon><MoreHoriz/></HelpfulHintIcon> and files can then be uploaded.</>,
]