import React from "react";
import { Typography, makeStyles, Link, Accordion, AccordionDetails, AccordionSummary, Tooltip, Dialog, DialogContent, Button } from '@material-ui/core';
import IAttachmentsGridProps from './IAttachmentsGridProps';
import AddTaskDocumentsButton from "./AddTaskDocumentsButton";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { GridApi, ICellRendererParams } from "@ag-grid-enterprise/all-modules";
import ITaskDocument from "../../interfaces/ITaskDocument";
import DataReadService from "../../services/DataReadService";
import DataWriteService from "../../services/DataWriteService";
import { EllipsisButtonActionRenderer } from "../AgGridRenderers/EllipsisButtonActionRenderer";
import IDeleteTaskDocumentDto from "../../interfaces/IDeleteTaskDocumentDto";
import { HierarchyType } from "../../helpers/enums/HierarchyType";
import { useSnackbar, VariantType } from 'notistack';
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import FileUploadDialog from "../FileUploadDialog/FileUploadDialog";
import { DownloadFileErrorText, DeleteDocumentMultipleTasksWarningText, DeleteDocumentWarningText } from "../../helpers/Constants";
import { agGridThemeOverride, border, detailPaneAccordionStyle } from "../../helpers/portalTheme";
import { useHistory } from "react-router-dom";
import { Utils } from "../../utilities/utils";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { saveAs } from 'file-saver';
import { TaskDocumentsDropzone } from "./TaskDocumentsDropzone";
import _ from "lodash";
import { PrivateFileCellRenderer } from "../AgGridRenderers/PrivateFileCellRenderer";
import { SpinnerButton } from "../common/SpinnerButton/SpinnerButton";
import ExpirationWarningIcon from "../ExpirationWarningIcon/ExpirationWarningIcon";
import { isExpirationDateExtensible } from "../../helpers/DocumentHelpers";
import { updateExpireDates } from "../../services/DocumentService";
import { useSelector } from "react-redux";
import ICurrentUser from "../../interfaces/ICurrentUser";
import MADialogTitle from "../MADialogTitle/MADialogTitle";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";
import { FileUploadProviderWrapper } from "../../contexts/FileUploadContext/FileUploadProviderWrapper";
import useAsyncFunctionIsLoading from "../../hooks/useIsLoadingAsync";
import SpinnerBackdrop from "../common/SpinnerBackdrop";

const errorGettingTaskDocumentsText = "There was an error retrieving the attachments for this request.";
const unrestrictedFilesWarningMessage = "Drag and drop uploads are visible to ALL users. For sensitive files, please use the Restricted option.";
const restrictedFilesUploadInstructions = 'Click on the + icon and select "Upload Restricted File" to limit an uploaded files access to a restricted set of users.'
const restrictedRequestInfoText = "This request is restricted. Only request Assignees and Moss Adams users can view this request and associated data, including attachments.";
export const successfullyAttachedFilesText = 'Successfully attached file(s).';
export const errorSeverity: VariantType = 'error';
export const successSeverity: VariantType = 'success';

const useStyles = makeStyles((theme) => ({
    root: {
        ...agGridThemeOverride,
        width: "100%",
        "&& .MuiDropzoneArea-root": {
            height: "100%"
        }
    },
    formControl: {
        width: "100%",
        marginBottom: theme.spacing(5),
    },
    attachmentsGrid: {
        flex: 1,
        minHeight: "400px",
        overflowY: "auto",
    },
    attachmentsGridDialog: {
        height: "calc(100% - 60px)"
    },
    attachmentHeader: {
        display: "flex",
        width: "100%",
    },
    summary: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        "&& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        }
    },
    infoIcon: {
        color: theme.palette.primary.contrastText,
    },
    attachmentTitle: {
        fontSize: "1.3rem",
        flex: 1
    },
    attachmentButton: {
        border: "1px dashed " + theme.palette.mossadams.gray200,
    },
    attachmentButtonDialog: {
        width: "100%",
        textAlign: "right"
    },
    accordion: {
        ...detailPaneAccordionStyle,
        "&& .MuiAccordion-root.Mui-expanded:last-child": {
            marginTop: "0px"
        }
    },
    attachmentDialog: {
        height: '90vh',
        "&& .MuiPaper-root": {
            height: "100%",
        },
        "&&  .MuiDialogContent-root > div": {
            height: "100%"
        }
    },
    attachmentDialogButton: {
        marginLeft: theme.spacing(4)
    }
}));

const AttachmentsGrid = (props: IAttachmentsGridProps): JSX.Element => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const [attachmentsGridApi, setAttachmentsGridApi] = React.useState<GridApi>();
    const [deleteConfirmDialogIsOpen, setDeleteConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [removeConfirmDialogIsOpen, setRemoveConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [selectedTaskDocument, setSeletectedTaskDocument] = React.useState<ITaskDocument | null>(null);
    const [uploadDialogIsOpen, setFileUploadDialogIsOpen] = React.useState(false);
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState<boolean>(Boolean(props.taskDocuments.length));
    const [addTaskDocumentFileUploadDialogIsOpen, setAddTaskDocumentFileUploadDialogIsOpen] = React.useState(false);
    const [attachmentDialogIsOpen, setAttachmentDialogIsOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (attachmentsGridApi) {
            getTaskDocuments();
        }
    }, [props.task.isRestricted])

    React.useEffect(() => {
        if (props.taskDocuments.length) {
            setIsAccordionExpanded(true);
        } else {
            setIsAccordionExpanded(false);
        }
    }, [props.taskDocuments.length]);

    React.useEffect(() => {
        props.setTaskDocuments([]);
        getTaskDocuments();
    }, [props.task.taskId]);

    const handleDeleteClick = () => {
        setDeleteConfirmDialogIsOpen(true);
    }

    const handleRemoveClick = () => {
        setRemoveConfirmDialogIsOpen(true);
    }

    const handleRemoveConfirmDialogClose = () => {
        setRemoveConfirmDialogIsOpen(false);
    };

    const handleDeleteConfirmDialogClose = () => {
        setDeleteConfirmDialogIsOpen(false);
    };

    const handleRemoveConfirmDialogConfirm = async () => {
        setDeleteConfirmDialogIsOpen(false);
        if (selectedTaskDocument) {
            handleRemoveTaskDocument(selectedTaskDocument);
        }
    };

    const handleDeleteConfirmDialogConfirm = async () => {
        setDeleteConfirmDialogIsOpen(false);
        if (selectedTaskDocument) {
            handleDeleteTaskDocument(selectedTaskDocument);
        }
    };

    const onAttachmentsGridReady = (params: any) => {
        setAttachmentsGridApi(params.api);
    }

    const components = {
        actionsRenderer: EllipsisButtonActionRenderer,
        privateFileCellRenderer: PrivateFileCellRenderer
    }

    const onUpdateTaskDocuments = (taskDocuments: ITaskDocument[], documentAdded: boolean = false) => {
        props.task.isRestricted && taskDocuments.forEach(td => td.isPrivate = props.task.isRestricted);
        props.setTaskDocuments(taskDocuments);
        attachmentsGridApi && attachmentsGridApi.setRowData(taskDocuments);

        const taskCopy = _.cloneDeep(props.task);
        taskCopy.hasAttachments = taskDocuments.length > 0;

        props.onTaskDocumentUpdated(taskCopy, documentAdded);
    }

    const { onTrigger: getTaskDocuments } = useAsyncFunctionIsLoading(async () => {
        if (!props.task.isNew && props.task.taskId) {
            const dataReadService = new DataReadService();

            const response = await dataReadService.GetTaskDocuments(props.task.taskId);
            if (response.status) {
                props.setTaskDocuments(response.data)
            } else {
                const errors = [errorGettingTaskDocumentsText, ...response.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    }, props.onLoadingStatusChanged)


    const { onTrigger: handleAddTaskDocument, loading: creatingTaskDocuments } = useAsyncFunctionIsLoading(async (uploadedFiles: IContentHierarchy[]) => {
        let contentHierarchies = uploadedFiles.filter(f => !props.taskDocuments.some(td => td.documentId === f.id));

        const taskDocumentsToAdd = contentHierarchies.map((contentHierarchy) => {
            const taskDocumentToAdd: ITaskDocument = {
                documentId: contentHierarchy.id,
                taskId: props.task.taskId,
                folderId: contentHierarchy.parentId,
                fileName: contentHierarchy.name,
                validFrom: new Date(),
                fileExtension: contentHierarchy.fileExtension,
                displayName: contentHierarchy.displayName,
                onMultipleTasks: false,
                isPrivate: contentHierarchy.isPrivate,
                expirationDate: new Date(),
            }
            return taskDocumentToAdd;
        })

        let taskDocumentsCopy = [...props.taskDocuments];
        if (taskDocumentsToAdd.length > 0) {

            const dataWriteService = new DataWriteService();
            const result = await dataWriteService.CreateTaskDocument(taskDocumentsToAdd);

            if (result.status) {

                taskDocumentsToAdd.forEach((taskDocumentToAdd) => {
                    const responseTaskDocument = result.response?.data.find((td: ITaskDocument) => td.displayName === taskDocumentToAdd.displayName)

                    taskDocumentsCopy.push({
                        ...taskDocumentToAdd,
                        onMultipleTasks: responseTaskDocument.onMultipleTasks,
                        validFrom: responseTaskDocument.validFrom
                    })
                });

                enqueueSnackbar(successfullyAttachedFilesText, { variant: successSeverity });
            }
            else {
                const errors = ["Failed to attach request document.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }

        onUpdateTaskDocuments(taskDocumentsCopy, true);
    });

    const handleRemoveTaskDocument = async (taskDocument: ITaskDocument) => {
        setRemoveConfirmDialogIsOpen(false);
        await removeTaskDocument(taskDocument);
    }

    const handleDeleteTaskDocument = async (taskDocument: ITaskDocument) => {
        setDeleteConfirmDialogIsOpen(false);
        await deleteTaskDocument(taskDocument);
    }

    const handleDownloadDocument = async (id: string, name: string) => {
        const dataReadService = new DataReadService();
        return await dataReadService.DownloadDocument(id).then((response) => {
            if (response.status) {
                //@ts-ignore createObjectURL expects Blob or MediaSource object.
                //The api returns a BlobPart(superset that contains Blob) but IPlatformApiGetResult casts it to a BlobPart[]
                let url = window.URL.createObjectURL(response.data);
                saveAs(url, name);
            } else {
                const errors = [DownloadFileErrorText, ...response.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        })
    }

    const handleUploadNewVersionClick = (taskDocument: ITaskDocument) => {
        setFileUploadDialogIsOpen(true);
    }

    const handleFileUploadDialogClose = () => {
        setFileUploadDialogIsOpen(false);
    }

    const handleNewVersionUploaded = (files: IContentHierarchy[]) => {
        if (files.length === 1) {
            const file = files[0];
            var doc = props.taskDocuments.find(t => t.documentId == file.id);
            if (doc) {
                doc.validFrom = file.updatedDateTime ? file.updatedDateTime : new Date();
                doc.displayName = file.name + "." + file.fileExtension;
                doc.fileName = file.name;
                props.setTaskDocuments([...props.taskDocuments]);

                if (attachmentsGridApi) {
                    attachmentsGridApi.setRowData(props.taskDocuments);
                }
            }
        }
    }

    const removeTaskDocument = async (taskDocument: ITaskDocument) => {
        if (taskDocument.documentId && props.task) {
            const dataWriteService = new DataWriteService();
            const model: IDeleteTaskDocumentDto = { TaskId: taskDocument.taskId!, DocumentId: taskDocument.documentId };
            const result = await dataWriteService.DeleteTaskDocument(model);
            if (result.status) {
                var doc = props.taskDocuments.find(t => t.documentId == taskDocument.documentId);
                if (doc) {
                    const filteredDocs = props.taskDocuments.filter((t: any) =>
                        t.documentId !== taskDocument.documentId
                    );
                    onUpdateTaskDocuments(filteredDocs, false);
                }

            } else {
                const errors = ["Failed to delete task document.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    }

    const deleteTaskDocument = async (taskDocument: ITaskDocument) => {
        if (taskDocument.documentId && props.task) {
            const dataWriteService = new DataWriteService();
            const result = await dataWriteService.DeleteDocument(taskDocument.documentId);
            if (result.status) {
                var doc = props.taskDocuments.find(t => t.documentId == taskDocument.documentId);
                if (doc) {
                    const filteredDocs = props.taskDocuments.filter((t: any) =>
                        t.documentId !== taskDocument.documentId
                    );
                    onUpdateTaskDocuments(filteredDocs, false);
                }

            }
            else {
                enqueueSnackbar("Failed to delete document.", { variant: SnackbarVariantTypes.Error });
            }
        }
    }

    const handleGridCellValueChanged = async (e: any) => {
        if (props.task) {
            var contentHeirarchy: IContentHierarchy = {
                id: e.data.documentId,
                name: e.data.fileName,
                hierarchyTypeId: HierarchyType.File.id,
                hierarchyTypeDescription: "",
                clientId: props.task.clientId || "",
                clientName: props.task.clientName || "",
                engagementId: props.task.engagementId || "",
                engagementName: props.task.engagementName || "",
                fileSize: 0,
                fileExtension: "",
                displayName: "",
                isPrivate: false,
                expirationDate: new Date(Date.now())
            };

            const dataWriteService = new DataWriteService();

            var result = await dataWriteService.UpdateDocument(contentHeirarchy);

            if (result.status) {
                var doc = props.taskDocuments.find(t => t.documentId == e.data.documentId);
                if (doc) {
                    doc.displayName = e.data.fileName + "." + e.data.fileExtension;
                    doc.fileName = e.data.fileName;
                    props.setTaskDocuments([...props.taskDocuments]);

                    if (attachmentsGridApi) {
                        attachmentsGridApi.setRowData(props.taskDocuments);
                    }
                }
            } else {
                var doc = props.taskDocuments.find(t => t.documentId == e.data.documentId);
                if (doc) {
                    doc.fileName = e.data.displayName.split(".")[0];
                    if (attachmentsGridApi) {
                        attachmentsGridApi.setRowData(props.taskDocuments);
                    }
                }

                const errors = ["Failed to update task document.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    }

    const getContextMenuItems = (params: any) => {

        if (params.node === null) {
            return;
        }

        const taskDocument: ITaskDocument = params.node.data;

        let result: any = [
            {
                name: "Delete",
                action: () => {
                    setSeletectedTaskDocument(taskDocument);
                    handleDeleteClick();
                }
            },
            {
                name: "File Details",
                action: () => {
                    history.push('/document-management/' + taskDocument.documentId);
                }
            },
            props.task.isRestricted ? null : {
                name: "Remove Attachment",
                action: () => {
                    setSeletectedTaskDocument(taskDocument);
                    handleRemoveClick();
                }
            },
            {
                name: "Rename",
                action: () => {
                    setSeletectedTaskDocument(taskDocument);
                    var startEditingParams = {
                        rowIndex: params.node.rowIndex,
                        colKey: "fileName"
                    };
                    params.api.startEditingCell(startEditingParams);
                }
            },
            params.node?.data?.isPrivate ? null : {
                name: "Upload New Version",
                action: () => {
                    setSeletectedTaskDocument(taskDocument);
                    handleUploadNewVersionClick(taskDocument);
                }
            },
            currentUser.isMossAdamsStaff && isExpirationDateExtensible(taskDocument.expirationDate) ? {
                name: "Extend Expiration Date",
                action: () => extendExpireDate(taskDocument)
            } : null
        ];

        return result.filter(Boolean);
    };

    const extendExpireDate = async (document: ITaskDocument) => {
        const result = await updateExpireDates([document]);
        if (result.status) {
            const updateTaskDocument = _.cloneDeep(props.taskDocuments.find(td => td.documentId === document.documentId));
            if (updateTaskDocument) {
                updateTaskDocument.expirationDate = result.response?.data?.[0]?.currentExpirationDate;
                const updatedTaskDocuments = props.taskDocuments.map(td => td.documentId === updateTaskDocument.documentId ? updateTaskDocument : td);
                onUpdateTaskDocuments(updatedTaskDocuments);
                enqueueSnackbar("Document expiration date extended by 30 days.", { variant: SnackbarVariantTypes.Success });
            }
        }
        else {
            enqueueSnackbar(result.errorMessages, { variant: SnackbarVariantTypes.Error });
        }
    }

    const uploadDisabled = (): boolean => {
        return (!props.task.allowMultipleAttachments && props.taskDocuments.length > 0)
            || props.taskIsNew
            || props.disabled;
    }

    const handleFullScreenClick = () => {
        setAttachmentDialogIsOpen(true);
    }

    const handleAttachmentsDialogClose = () => {
        setAttachmentDialogIsOpen(false);
    }

    const attachmentButtonProps = {
        addTaskDocumentFileUploadDialogIsOpen: addTaskDocumentFileUploadDialogIsOpen,
        setAddTaskDocumentFileUploadDialogIsOpen: setAddTaskDocumentFileUploadDialogIsOpen,
        className: classes.attachmentButtonDialog,
        clientName: props.clientName || "",
        engagementName: props.engagementName || "",
        uploadFolderId: props.uploadFolderId,
        onAddTaskDocuments: handleAddTaskDocument,
        disabled: uploadDisabled(),
        documentCount: props.taskDocuments.length,
        allowMultipleAttachments: props.allowMultipleAttachments,
        task: props.task
    }

    const getNoRowsOverlayComponent = (params: any) => {
        return params.isRestricted
            ? restrictedRequestInfoText
            : unrestrictedFilesWarningMessage
    }

    const attachmentDetails =
        <div className={"ag-theme-alpine " + classes.formControl + " " + (attachmentDialogIsOpen == true ? classes.attachmentsGridDialog : classes.attachmentsGrid)} >
            <AgGridReact
                rowData={props.taskDocuments}
                onGridReady={onAttachmentsGridReady}
                getRowId={(row: any) => row.data.documentId}
                components={components}
                onCellValueChanged={handleGridCellValueChanged}
                editType=""
                suppressClickEdit={true}
                getContextMenuItems={getContextMenuItems}
                onCellClicked={(params: any) => {
                    if (params.column.colDef.field === '...') {
                        params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)
                    }
                }}
                onCellContextMenu={(params: any) => {
                    params.api.contextMenuFactory.hideActiveMenu()
                }}
                noRowsOverlayComponent={getNoRowsOverlayComponent}
                noRowsOverlayComponentParams={{ isRestricted: props.task.isRestricted }}
                stopEditingWhenCellsLoseFocus={true}
                defaultColDef={{
                    editable: false,
                    resizable: true,
                    sortable: true,
                    filter: true,
                    floatingFilter: false,
                    menuTabs: ['generalMenuTab', 'filterMenuTab']
                }}
            >
                <AgGridColumn
                    field="fileName"
                    colId="fileName"
                    flex={.9}

                    cellRenderer={(params: any) => {
                        const docId = params.node.data.documentId;
                        const name = params.data.displayName;
                        return <SpinnerButton
                            onClickAsync={() => handleDownloadDocument(docId, params.node.data.displayName)}
                            variant='link'
                            buttonText={name}
                        />
                    }
                    }
                    editable={!props.disabled}
                />
                <AgGridColumn
                    field="validFrom"
                    headerName="Uploaded On"
                    flex={.6}
                    valueFormatter={(params: any) => {
                        return LocalDateTimeString(params.value, false);
                    }}
                    editable={false}
                    cellRenderer={(params: ICellRendererParams) => {
                        return <>
                            {LocalDateTimeString(params.data.validFrom, false)}<ExpirationWarningIcon date={params.data?.expirationDate} />
                        </>
                    }}
                />
                <AgGridColumn
                    field="isPrivate"
                    headerComponent={() => <RestrictedIcon useGridStyle />}
                    width={50}
                    cellRenderer="privateFileCellRenderer"
                    editable={false}
                />
                <AgGridColumn
                    headerName=""
                    field="..."
                    cellRenderer="actionsRenderer"
                    editable={false}
                    filter={false}
                    width={45}
                    hide={props.disabled}
                    suppressMenu={true}
                />
            </AgGridReact>
        </div>

    return (
        <>
            <SpinnerBackdrop isActive={creatingTaskDocuments} />
            <FileUploadProviderWrapper
                folderId={selectedTaskDocument?.folderId}
                isRestrictedFileUpload={false}
                newVersionDocumentId={selectedTaskDocument?.documentId}
                suppressNotifications={true}
            >
                <FileUploadDialog
                    isOpen={uploadDialogIsOpen}
                    clientName={""}
                    engagementName={""}
                    folderId={selectedTaskDocument?.folderId}
                    fileName={selectedTaskDocument?.displayName}
                    fileExt={selectedTaskDocument?.fileExtension}
                    fileId={selectedTaskDocument?.documentId}
                    uploadNewVersion={true}
                    isSingleFile={true}
                    onClose={handleFileUploadDialogClose}
                    onFilesUploaded={handleNewVersionUploaded}
                />
            </FileUploadProviderWrapper>
            <ConfirmDialog
                isOpen={deleteConfirmDialogIsOpen}
                onClose={handleDeleteConfirmDialogClose}
                onConfirm={handleDeleteConfirmDialogConfirm}
                title="Delete Document"
                children={<Typography>{selectedTaskDocument?.onMultipleTasks ? DeleteDocumentMultipleTasksWarningText : DeleteDocumentWarningText}</Typography>}
            />
            <ConfirmDialog
                isOpen={removeConfirmDialogIsOpen}
                onClose={handleRemoveConfirmDialogClose}
                onConfirm={handleRemoveConfirmDialogConfirm}
                title="Remove Attachment"
                children={<Typography>{`This will remove the attachment from the request but not delete it from the Documents Library. Do you want to proceed?`}</Typography>}
            />
            <div className={classes.root}>
                {attachmentDialogIsOpen ?
                    <Dialog open={attachmentDialogIsOpen} aria-labelledby="attachments-dialog" fullWidth={true} maxWidth={"lg"} className={classes.attachmentDialog}  >
                        <MADialogTitle id="upload-file-dialog-title" onClose={handleAttachmentsDialogClose}>
                            Attachments - {props.task.taskNumber} - {props.task.title}
                        </MADialogTitle>
                        <DialogContent>
                            <TaskDocumentsDropzone
                                task={props.task}
                                taskDocuments={props.taskDocuments}
                                uploadFolderId={props.uploadFolderId}
                                fileUploadDialogIsOpen={addTaskDocumentFileUploadDialogIsOpen}
                                disabled={uploadDisabled()}
                                onFilesUploaded={handleAddTaskDocument}
                            >
                                {attachmentDetails}
                                <div>
                                    <AddTaskDocumentsButton
                                        {...attachmentButtonProps}
                                        className={classes.attachmentButtonDialog}
                                    />
                                </div>
                            </TaskDocumentsDropzone>
                        </DialogContent>
                    </Dialog>
                    :
                    <TaskDocumentsDropzone
                        task={props.task}
                        taskDocuments={props.taskDocuments}
                        uploadFolderId={props.uploadFolderId}
                        fileUploadDialogIsOpen={addTaskDocumentFileUploadDialogIsOpen}
                        disabled={uploadDisabled()}
                        onFilesUploaded={handleAddTaskDocument}
                    >
                        <Accordion
                            defaultExpanded
                            className={classes.accordion}
                            expanded={isAccordionExpanded}
                            onChange={(event, expanded) => { setIsAccordionExpanded(expanded) }}
                            disabled={props.disabled || uploadDialogIsOpen || deleteConfirmDialogIsOpen || removeConfirmDialogIsOpen}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="AttachmentsGrid-content"
                                id="AttachmentsGrid-header"
                                className={classes.summary}
                            >
                                <div className={classes.attachmentHeader}>
                                    <Typography className={classes.attachmentTitle}>
                                        Attachments <Tooltip style={{ verticalAlign: 'middle' }}
                                            title={props.task.isRestricted ? restrictedRequestInfoText : unrestrictedFilesWarningMessage.concat(' ', restrictedFilesUploadInstructions)}
                                            color="primary"
                                        >
                                            <InfoIcon className={classes.infoIcon} />
                                        </Tooltip>
                                    </Typography>
                                    <AddTaskDocumentsButton
                                        {...attachmentButtonProps}
                                        className={classes.attachmentButton}
                                    />
                                    <Tooltip title='Expand View'>
                                        <Button onClick={handleFullScreenClick} variant="contained" color="primary" className={classes.attachmentDialogButton}>
                                            <ZoomOutMapIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {attachmentDetails}
                            </AccordionDetails>
                        </Accordion>
                    </TaskDocumentsDropzone>
                }
            </div>
        </>
    );
};

export default AttachmentsGrid;
