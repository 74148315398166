import { RowNode, GridApi, RefreshCellsParams, RowDragMoveEvent, RowDragLeaveEvent, RowDragEndEvent } from '@ag-grid-enterprise/all-modules';
import React, { useCallback } from 'react'
import { getUpdatedSubtreeForUpdatedFolder, hierarchyIsFile, hierarchyIsFolder, hierarchyIsRestrictedFolder } from '../../../helpers/ContentHierarchyHelpers';
import { useMoveDocuments } from '../../../hooks/useMoveDocuments';
import IContentHierarchy from '../../../interfaces/IContentHierarchy';
import { useMoveFolders } from '../../../hooks/useMoveFolders';
import IPlatformApiPostResult from '../../../interfaces/IPlatformApiPostResult';

export const useMoveContentHierarchy = (gridApi: GridApi | undefined | null, requestConfirmation: Function, onMoveHierarchy: Function) => {
    const [potentialParent, setPotentialParent] = React.useState<RowNode | null>(null);
    const [onUpdatePotentialParent, setOnUpdatePotentialParent] = React.useState<Function>();

    const { moveDocuments, loading: movingDocuments } = useMoveDocuments();
    const { moveFolders, loading: movingFolders } = useMoveFolders();

    React.useEffect(() => {
        onUpdatePotentialParent && onUpdatePotentialParent();
    }, [potentialParent])

    const setPotentialParentForNode = (api: GridApi, overNode: RowNode | undefined | null, dragNode?: RowNode) => {
        let newPotentialParent: RowNode | null | undefined;
        if (overNode) {
            newPotentialParent =
                hierarchyIsFolder(overNode.data)
                    ? overNode
                    : overNode.parent;
        }
        else {
            newPotentialParent = null;
        }

        const alreadySelected = potentialParent === newPotentialParent;
        if (alreadySelected) {
            return;
        }

        const dragHierarchy: IContentHierarchy = dragNode?.data;
        const newPotentialParentHierarchy: IContentHierarchy = newPotentialParent?.data;
        const overNodeIsDragNode = overNode !== null && dragHierarchy.path === newPotentialParentHierarchy.path;
        const newPotentialParentIsCurrentParent = newPotentialParentHierarchy.id === dragHierarchy.parentId;
        if (overNodeIsDragNode || newPotentialParentIsCurrentParent) {
            newPotentialParent = null;
        }

        setPotentialParent(newPotentialParent);
        setOnUpdatePotentialParent(() => refreshRows(api, getRowsToRefresh(newPotentialParent)));
    }

    const getRowsToRefresh = (newPotentialParent?: RowNode | null) => {
        let rowsToRefresh: RowNode[] = [];
        if (potentialParent) {
            rowsToRefresh.push(potentialParent);
        }
        if (newPotentialParent) {
            rowsToRefresh.push(newPotentialParent);
        }
        return rowsToRefresh;
    }

    const clearPotentialParent = () => {
        if (gridApi) {
            setPotentialParent(null);
            setOnUpdatePotentialParent(() => refreshRows(gridApi, getRowsToRefresh()));
        }
    }

    const onRowDragMove = useCallback((event: RowDragMoveEvent) => {
        setPotentialParentForNode(event.api, event.overNode, event.node);
    }, []);

    const onRowDragLeave = useCallback((event: RowDragLeaveEvent) => {
        clearPotentialParent();
    }, []);

    const hierarchyMoveSucceeded = useCallback(async (hierarchy: IContentHierarchy, newParent: IContentHierarchy) => {
        let response: IPlatformApiPostResult | undefined;

        if (hierarchyIsFile(hierarchy)) {
            response = await moveDocuments([hierarchy], newParent);
        }
        else if (hierarchyIsFolder(hierarchy)) {
            response = await moveFolders([hierarchy], newParent);
        }

        return Boolean(response?.status)
    }, [])

    const isMoveConfirmed = async (currentParent: IContentHierarchy | undefined, newParent: IContentHierarchy) => {
        if (currentParent && hierarchyIsRestrictedFolder(currentParent) && !hierarchyIsRestrictedFolder(newParent)) {
            return await requestConfirmation();
        }
        return true;
    }

    const onRowDragEnd = useCallback(async (event: RowDragEndEvent) => {
        if (!potentialParent) {
            return;
        }
        const hierarchyToMove: IContentHierarchy = event.node.data;
        const currentParent = event.node.parent?.data;
        const newParent = potentialParent.data;

        const isNotRoot = potentialParent.data;
        let newParentPath = isNotRoot
            ? potentialParent.data.path
            : [];
        let parentNeedsUpdate = !arePathsEqual(
            newParentPath,
            hierarchyToMove.path!,
            hierarchyIsFile(hierarchyToMove)
        );

        let invalidMove = isSelectionParentOfTarget(event.node, potentialParent);
        if (parentNeedsUpdate && !invalidMove && await isMoveConfirmed(currentParent, newParent) && await hierarchyMoveSucceeded(hierarchyToMove, newParent)) {
            const updatedData: IContentHierarchy[] = getUpdatedSubtreeForUpdatedFolder(event.node, potentialParent);
            gridApi?.applyTransaction({
                update: updatedData,
            });

            gridApi?.setRowNodeExpanded(potentialParent, true);
            gridApi?.ensureNodeVisible(updatedData[0], 'middle');
            gridApi?.selectNode(event.node);
            gridApi?.clearFocusedCell();

            onMoveHierarchy(hierarchyToMove);
        }

        clearPotentialParent();
    }, [potentialParent]);

    return { potentialParent, onRowDragMove, onRowDragLeave, onRowDragEnd, loading: movingDocuments || movingFolders }
}

function isSelectionParentOfTarget(selectedNode: RowNode, targetNode: RowNode) {
    let children = selectedNode.childrenAfterGroup || [];
    for (let i = 0; i < children.length; i++) {
        if (targetNode && children[i].key === targetNode.key) return true;
        isSelectionParentOfTarget(children[i], targetNode);
    }
    return false;
}

function arePathsEqual(targetPath: string[], movePath: string[], isFile: boolean) {
    movePath = isFile ? movePath.slice(0, -1) : movePath;
    if (targetPath.length !== movePath.length) {
        return false;
    }

    let equal = true;
    targetPath.forEach((item, index) => {
        if (movePath[index] !== item) {
            equal = false;
        }
    });
    return equal;
}

function refreshRows(api: GridApi, rowsToRefresh: RowNode[]) {
    let params: RefreshCellsParams = {
        rowNodes: rowsToRefresh,
        force: true,
    };
    api.refreshCells(params);
}