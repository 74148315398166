import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { VariantType } from 'notistack';
import { useSnackbar } from 'notistack';
import IFolderDialogProps from './IFolderDialogProps';
import DataWriteService from '../../services/DataWriteService';
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import ICreateFolderModel from '../../interfaces/ICreateFolderModel';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import MADialogTitle from '../MADialogTitle/MADialogTitle'
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import { Utils } from '../../utilities/utils';
import { ManageFolderAccessModalContent } from '../ManageFolderAccessModal/ManageFolderAccessModalContent';
import { RestrictedUserOption } from '../RestrictedFileAssociationDialog/RestrictedUserAutocomplete';

export const errorCreatingFolderText = 'There was an error creating the folder.';
export const errorUpdatingFolder = 'There was an error updating the folder.';
export const successfullyCreatedFolderText = 'Folder created successfully.';
export const successfullyUpdatedFolder = 'Folder updated successfully.';
export const errorSeverity: VariantType = 'error';
export const successSeverity: VariantType = 'success';


export const FolderDialog = (props: IFolderDialogProps): JSX.Element => {

    const [folderName, setFolderName] = React.useState<string>("");
    const [userOptions, setUserOptions] = React.useState<RestrictedUserOption[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (props.isRename && props.currentContentHierarchy) {
            setFolderName(props.currentContentHierarchy.name);
        } else {
            setFolderName("");
        }
    }, [props]);

    const createFolder = async () => {
        const service = new DataWriteService();
        let result: IPlatformApiPostResult;
        const model: ICreateFolderModel = { 
            parentFolderId: props.parentFolderId, 
            folderName: folderName,
            permittedUsers: props.isRestrictedFolder ? userOptions.map(option => option.userId) : undefined,
        };
        result = await service.CreateFolder(model);

        if (result.status) {
            enqueueSnackbar(successfullyCreatedFolderText, { variant: successSeverity });
            handleClose();
            props.onFolderCreated(result.response?.data);
        } else {
            const errors = [errorCreatingFolderText, ...result.errorMessages];
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    }

    const updateFolder = async () => {
        const service = new DataWriteService();
        let result: IPlatformApiPostResult;
        let folder: IContentHierarchy = { ...props.currentContentHierarchy! };
        folder.name = folderName;

        result = await service.UpdateFolder(folder);

        if (result.status) {
            enqueueSnackbar(successfullyUpdatedFolder, { variant: successSeverity });
            handleClose();
            props.onFolderRenamed(result.response?.data);
        } else {
            const errors = [errorUpdatingFolder, ...result.errorMessages];
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    }

    const handleClose = () => {
        setFolderName("");
        props.onClose();
    }

    const handleFolderNameChange = (e: any) => {
        const newFolderName = e.target.value;
        setFolderName(newFolderName);
    }

    const validateName = () => {
        let isValid = true;
        let errors = "";

        if (!folderName) {
            isValid = false;
            let errors = "";
            errors = "Folder name is required.";
        }
        else if (folderName.length < 3 || folderName.length > 50) {
            isValid = false;
            errors = "Folder name length must be between 3 and 50.";
        }
        else if (RegExp("^[\\w\\-. ]+$").test(folderName) === false) {
            isValid = false;
            errors = 'Folder name may only contain letters, numbers, "-", "_" and "."';
        }
        return {isValid, errors};
    }
    const {isValid: isFileNameValid, errors: folderNameValidationMessage} = validateName();

    const dialogContent = <>
        <TextField
            autoFocus
            error={!isFileNameValid}
            helperText={folderNameValidationMessage}
            id="name"
            label="Title"
            type="text"
            fullWidth
            required={true}
            value={folderName}
            onChange={handleFolderNameChange}
        />
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Client"
            type="text"
            disabled={true}
            defaultValue={props.clientName}
            fullWidth
        />
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Engagement"
            type="text"
            disabled={true}
            defaultValue={props.engagementName}
            fullWidth
        />
    </>

    return (
        <Dialog open={props.isOpen}>
            <MADialogTitle id="new-folder-dialog-title" onClose={handleClose}>
                {props.isRename ? "Rename Folder" : "Create Folder"}
            </MADialogTitle>
            <DialogContent>
                {dialogContent}
                {props.isRestrictedFolder &&
                    <ManageFolderAccessModalContent
                        userOptions={userOptions}
                        setUserOptions={setUserOptions}
                        folder={props.currentContentHierarchy!}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.isRename ? updateFolder : createFolder} color="primary" fullWidth={true} variant="outlined" disabled={!isFileNameValid}>
                    {props.isRename ? "Rename Folder" : "Create Folder"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}